.o-bg {
  &--black {
    background: $color-black;
  }

  &--cover {
    background-size: cover;
    background-repeat: no-repeat;
  }
}
