@charset "UTF-8";
/*
  Project: Custom34
  Author: Daniel
 */
@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap&subset=latin-ext';
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.9rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #050000;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.8125rem;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 768px) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 0.9375em;
    /* 15px */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1em;
    /* 16px */
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #666;
  text-decoration: underline;
}

a:hover {
  color: #999;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.o-animate--opacity {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.o-animate--fadeIn {
  opacity: 1;
}

.o-animate--fadeInWithSlide {
  opacity: 1;
  animation: slideBottom 1s;
}

@keyframes slideBottom {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0);
  }
}

.o-bg--black {
  background: #050000;
}

.o-bg--cover {
  background-size: cover;
  background-repeat: no-repeat;
}

.o-hamburger {
  border: none;
  background: none;
  appearance: none;
  outline: none;
  padding: 0;
  width: 2rem;
  cursor: pointer;
}

.o-hamburger > span {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  height: 2px;
}

.o-hamburger > span:first-child {
  top: 0;
  margin-bottom: 0.4rem;
  transition: top 0.2s ease-in-out 0s;
}

.o-hamburger > span:last-child {
  bottom: 0;
  margin-top: 0.4rem;
  transition: bottom 0.1s ease-in-out 0s;
}

.o-hamburger:hover > span, .o-hamburger:focus > span {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  height: 2px;
}

.o-hamburger:hover > span:first-child, .o-hamburger:focus > span:first-child {
  top: -2px;
}

.o-hamburger:hover > span:last-child, .o-hamburger:focus > span:last-child {
  bottom: -2px;
}

.o-heading {
  font-size: 1.7rem;
  text-transform: lowercase;
}

.o-heading--white {
  color: #fff;
}

.o-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
}

.o-icon--inline {
  display: inline-flex;
}

.o-icon--icon-fb {
  width: 1em;
}

.o-icon--icon-yt {
  width: 1em;
}

.o-icon--icon-ig {
  width: 1em;
}

.o-icon--icon-logo {
  width: 1em;
}

.o-icon--icon-small-arrow {
  width: 1em;
}

.o-icon__symbol {
  display: block;
  width: 100%;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

.o-picture {
  display: block;
}

.o-picture__inner {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
}

.o-picture--tile-featured .o-picture__inner {
  padding-top: 100%;
}

@media (min-width: 768px) {
  .o-picture--tile-featured .o-picture__inner {
    padding-top: 100%;
  }
}

.o-picture--tile-artist .o-picture__inner {
  padding-top: 100%;
}

@media (min-width: 768px) {
  .o-picture--tile-artist .o-picture__inner {
    padding-top: 100%;
  }
}

.o-picture--tile-gallery .o-picture__inner {
  padding-top: 100%;
}

@media (min-width: 768px) {
  .o-picture--tile-gallery .o-picture__inner {
    padding-top: 100%;
  }
}

.o-picture__image {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: none;
  height: 100.2%;
  transform: translate(-50%, -50%);
  transition: all 500ms cubic-bezier(0.24, 0.45, 0.39, 0.95);
  backface-visibility: hidden;
}

@supports (object-fit: cover) {
  .o-picture__image {
    width: 100.2%;
    object-fit: cover;
    object-position: center center;
  }
}

.o-picture--contain .o-picture__image {
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

@supports (object-fit: contain) {
  .o-picture--contain .o-picture__image {
    height: 100%;
    object-fit: contain;
  }
}

.o-picture:hover .o-picture__image,
.c-tiles__item-link:hover .o-picture__image,
.c-tiles__box .o-picture__image {
  transform: translate(-50%, -50%) scale(1.1);
}

.o-picture--still:hover .o-picture__image {
  transform: translate(-50%, -50%);
}

.o-picture__caption {
  display: none;
}

.o-section__content {
  font-size: 1rem;
}

.o-section__content--white {
  color: #fff;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-banner {
  position: relative;
}

.c-banner__item {
  position: relative;
  height: 0;
  padding-bottom: 39%;
  background-size: cover;
  background-repeat: no-repeat;
}

.c-banner__item video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 100vw;
}

.c-banner__heading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #fff;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
}

.c-banner__indicators {
  position: absolute;
  bottom: 1rem;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
}

.c-banner__indicator {
  border: none;
  background: none;
  appearance: none;
  outline: none;
  padding: 0;
  width: 0.8rem;
  height: 0.8rem;
  background: #ccc;
  border-radius: 50%;
  margin: 0.2rem;
  cursor: pointer;
}

.c-banner__indicator.is-active {
  background: #ed1c24;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.5rem 1rem;
  transition: all 300ms ease-in-out;
  border-radius: 3px;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #ed1c24;
}

.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
  color: #fff;
}

.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #f2484f;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-contact__info-block {
  margin: 70px auto 0;
  max-width: 45rem;
}

@media (min-width: 768px) {
  .c-contact__info-block {
    display: flex;
    justify-content: space-between;
  }
}

.c-contact__info-block__item {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  text-align: left;
}

.c-contact__info-block__item:first-child {
  margin-top: 0;
}

@media (min-width: 768px) {
  .c-contact__info-block__item {
    margin-top: 0;
    width: 49%;
  }
}

.c-contact__info-block__icon {
  width: 15px;
  display: block;
}

.c-contact__info-block__info {
  position: relative;
  width: auto;
  padding-left: 50px;
}

.c-contact__info-block__info:before {
  content: "";
  position: absolute;
  display: block;
  left: 32px;
  height: 100%;
  width: 1px;
  background: #fff;
}

.c-contact__info-block__title {
  color: #fff;
  font-size: 1rem;
  margin: 0;
  text-transform: uppercase;
}

.c-contact__info-block__link {
  color: #fff;
  display: block;
  font-size: 1rem;
  text-decoration: none;
  position: relative;
}

.c-contact__info-block__link .o-icon {
  position: absolute;
  left: -38px;
  top: 50%;
  transform: translateY(-50%);
}

.c-contact__rodo {
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #fff;
  margin: 80px auto 0;
}

.c-contact__rodo a {
  color: #fff;
  font-weight: 700;
}

.cookies {
  position: fixed;
  background: #eee;
  max-width: 100%;
  bottom: 0;
  display: none;
  left: 0;
  right: 0;
  font-size: 12px;
  font-weight: 400;
  z-index: 10000;
  padding: 10px 20px;
  text-align: center;
}

.cookies a {
  color: #666;
  text-decoration: underline;
}

.accept-cookie {
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  display: inline;
}

.cookie-toaccept {
  display: block;
}

.c-divider {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.c-divider--small {
  padding-bottom: 25%;
}

.c-divider--big {
  padding-bottom: 40%;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  padding: 1rem;
  font-size: 0.85rem;
  font-weight: 700;
  color: #fff;
}

@media (min-width: 768px) {
  .c-footer__wrapper {
    display: flex;
    align-items: flex-end;
  }
}

.c-footer__logo-link {
  font-size: 3.9rem;
}

.c-footer__address {
  margin-top: 1.5rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .c-footer__address {
    margin-top: 0;
    margin-left: 2.5rem;
  }
}

.c-footer__copy {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  margin-left: auto;
}

@media (min-width: 768px) {
  .c-footer__copy {
    margin-top: 0;
  }
}

.c-footer__copy-line {
  margin: 0;
  padding: 0;
}

.c-footer__designer-logo {
  display: inline-block;
  width: 3.8rem;
  margin-bottom: 1px;
}

.c-form {
  margin: 50px auto 0;
}

@media (min-width: 768px) {
  .c-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .c-form__group {
    width: 49%;
  }
}

.c-form__input {
  margin-top: 2.4rem;
}

.c-form__input:first-child {
  margin-top: 0;
}

@media (min-width: 768px) {
  .c-form__input {
    padding-right: 5rem;
  }
}

.c-form__input input {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 700;
  width: 100%;
  padding-bottom: 0.8rem;
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
}

.c-form__input input::placeholder {
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
}

input, textarea {
  outline: none;
}

.c-form__textarea {
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .c-form__textarea {
    margin-top: 0;
  }
}

.c-form__textarea label {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0.8rem;
}

.c-form__textarea textarea {
  color: #fff;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  resize: none;
  background: transparent;
  border: 1px solid #fff;
}

.c-form__accept {
  margin-top: 2rem;
}

.c-form__accept-explain {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 400;
  display: block;
  color: #fff;
  letter-spacing: 1px;
}

.c-form__label {
  display: flex;
  align-items: center;
}

.c-form__checkbox {
  margin-top: 5px;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.c-form__checkbox label {
  margin-left: 3px;
}

.c-form__checkbox a {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

input[type='checkbox'] {
  position: absolute;
  z-index: -2222;
}

input[type='checkbox']:checked + .c-form__label .c-form__real-checkbox:before {
  content: '';
  position: absolute;
  display: block;
  width: 6px;
  height: 6px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
}

.c-form__send {
  margin-top: 1.5rem;
  border: none;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 0;
  font-size: 0.9rem;
  padding: 1rem 4rem;
  text-transform: uppercase;
}

.c-form__real-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border: 1px solid #fff;
}

.c-form__message {
  border: 2px solid #fff;
  padding: 2rem;
  color: #fff;
}

.c-form__message--error {
  border-color: #ed1c24;
  color: #ed1c24;
}

.c-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #050000;
  z-index: 9;
}

.c-header__inner {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
}

.c-header__left,
.c-header__right {
  display: flex;
  flex-direction: column;
}

.c-header__nav-item {
  transition: transform 0.1s ease-in;
}

.c-header__nav-item:hover {
  transform: translateY(-0.1rem);
}

.c-header__logo-link {
  display: flex;
  margin: 1rem 0;
}

.c-header__left-wrapper,
.c-header__nav {
  margin-top: auto;
  margin-bottom: 1.3rem;
}

.c-header__nav-list {
  display: flex;
  list-style-type: none;
  margin: 0;
}

.c-header__nav-item {
  height: 1rem;
  margin: 0;
  margin-left: 0.5rem;
}

.c-header__logo {
  font-size: 3.9rem;
}

.c-header__left-wrapper {
  display: flex;
  align-items: center;
}

.c-header__lang-switch {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 700;
  color: #ed1c24;
  text-transform: uppercase;
  margin-left: 0.75rem;
}

.c-header__lang-switch:hover {
  color: rgba(237, 28, 36, 0.8);
}

.c-header__lang-switch:hover .o-icon {
  left: 0.3rem;
  opacity: 0.8;
}

.c-header__lang-switch .o-icon {
  position: relative;
  left: 0.2rem;
  font-size: 0.5rem;
  transition: left 0.1s ease-in-out 0s;
}

.c-inventory__item {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .c-inventory__item {
    width: 33%;
    padding: 0 4rem;
  }
}

.c-inventory__item-title {
  font-weight: 700;
  font-size: 1.5rem;
}

.c-link-with-icon {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.c-link-with-icon__icon {
  text-align: center;
}

.c-link-with-icon__link {
  text-decoration: none;
}

.c-link-with-icon__icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  transition: transform 0.1s ease-in-out;
}

.c-link-with-icon__link:hover .c-link-with-icon__icon {
  transform: scale(1.1);
}

.c-link-with-icon__title {
  position: relative;
  display: inline-block;
  margin-top: 0.75rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
}

.c-link-with-icon__img {
  height: 2.4rem;
}

.c-load-more {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
}

.c-load-more__link {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.9rem;
  color: #050000;
  text-align: center;
}

.c-load-more__link:hover {
  color: #050000;
}

.c-load-more__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
  background-color: #ed1c24;
  border-radius: 50%;
  font-size: 1.1rem;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  transition: transform 0.1s ease-in;
}

.c-load-more__link:hover .c-load-more__icon {
  transform: scale(1.3);
}

.c-main-nav {
  position: fixed;
  top: -100vh;
  left: 0;
  right: 0;
  z-index: 15;
  background: rgba(5, 0, 0, 0.95);
  min-height: 100vh;
  z-index: 9999;
  transition: top 0.3s ease-in-out;
}

.c-main-nav--show {
  top: 0;
}

.c-main-nav__wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.c-main-nav__items {
  margin-top: 3.6rem;
}

.c-navbar__logo {
  display: flex;
  justify-content: center;
}

.c-main-nav__close {
  border: none;
  background: none;
  appearance: none;
  outline: none;
  padding: 0;
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 9;
}

.c-main-nav__close span {
  display: inline-block;
  position: absolute;
  width: 3px;
  height: 100%;
  transform: rotate(45deg);
  background: #ed1c24;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.c-main-nav__close span:first-child {
  transform: rotate(-45deg);
}

.c-main-nav__item {
  font-weight: 400;
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.8;
}

@media (min-width: 768px) {
  .c-main-nav__item {
    font-size: 1.2rem;
    line-height: 2.2;
  }
}

.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__link {
  display: inline;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}

.c-main-nav__link:hover {
  color: #ed1c24;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.c-map__icon {
  background: #ed1c24;
  border-radius: 50%;
  position: relative;
}

.c-map__icon::after {
  content: 'CUSTOM34';
  position: absolute;
  left: 35px;
  top: 7px;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-tiles {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
  overflow: hidden;
}

.c-tiles--featured {
  justify-content: center;
}

.c-tiles--artist, .c-tiles--gallery {
  flex-wrap: wrap;
}

.c-tiles__item-link {
  position: relative;
  margin: 1rem;
  width: 59%;
}

.c-tiles__item-link--artist, .c-tiles__item-link--gallery {
  width: calc(50% - 2rem);
}

@media (min-width: 768px) {
  .c-tiles__item-link--artist, .c-tiles__item-link--gallery {
    width: calc(25% - 2rem);
  }
}

.c-tiles__item-title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 1.8rem;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  z-index: 2;
}

.c-tiles__box {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 4rem;
  background: rgba(5, 0, 0, 0.7);
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.75rem;
  transition: background 0.3s ease-in;
}

.c-tiles__item-link:hover .c-tiles__box {
  background: rgba(5, 0, 0, 0.9);
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.u-px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.u-py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.u-py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.u-py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.u-py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.u-py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.u-py-8 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.u-pb-8 {
  padding-bottom: 4rem;
}

.u-text-white {
  color: #fff;
}

/*!
 * baguetteBox.js
 * @author  feimosi
 * @version 1.11.1
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: opacity .5s ease;
  transition: opacity .5s ease;
}

#baguetteBox-overlay.visible {
  opacity: 1;
}

#baguetteBox-overlay .full-image {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

#baguetteBox-overlay .full-image figure {
  display: inline;
  margin: 0;
  height: 100%;
}

#baguetteBox-overlay .full-image img {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

#baguetteBox-overlay .full-image figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 1.8;
  white-space: normal;
  color: #ccc;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
  font-family: sans-serif;
}

#baguetteBox-overlay .full-image:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  -webkit-transition: left .4s ease, -webkit-transform .4s ease;
  transition: left .4s ease, -webkit-transform .4s ease;
  transition: left .4s ease, transform .4s ease;
  transition: left .4s ease, transform .4s ease, -webkit-transform .4s ease, -moz-transform .4s ease;
}

#baguetteBox-slider.bounce-from-right {
  -webkit-animation: bounceFromRight .4s ease-out;
  animation: bounceFromRight .4s ease-out;
}

#baguetteBox-slider.bounce-from-left {
  -webkit-animation: bounceFromLeft .4s ease-out;
  animation: bounceFromLeft .4s ease-out;
}

@-webkit-keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  100% {
    margin-left: 0;
  }
}

@-webkit-keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0;
  }
}

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px;
}

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  -moz-border-radius: 15%;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  -webkit-transition: background-color .4s ease;
  transition: background-color .4s ease;
}

.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: rgba(50, 50, 50, 0.9);
}

.baguetteBox-button#next-button {
  right: 2%;
}

.baguetteBox-button#previous-button {
  left: 2%;
}

.baguetteBox-button#close-button {
  top: 20px;
  right: 2%;
  right: calc(2% + 6px);
  width: 30px;
  height: 30px;
}

.baguetteBox-button svg {
  position: absolute;
  left: 0;
  top: 0;
}

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */
.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(images/marker-icon.png);
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

#map {
  height: 60vh;
}

#map img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

#map .leaflet-marker-pane img {
  filter: none;
  -webkit-filter: none;
}

.g-recaptcha {
  padding-top: 20px;
  margin-bottom: 20px;
}
