.c-main-nav {
  position: fixed;
  top: -100vh;
  left: 0;
  right: 0;
  z-index: 15;
  background: rgba($color-black, 0.95);
  min-height: 100vh;
  z-index: 9999;

  transition: top 0.3s ease-in-out;

  &--show {
    top: 0;
  }
}

.c-main-nav__wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.c-main-nav__items {
  margin-top: 3.6rem;
}

.c-navbar__logo {
  display: flex;
  justify-content: center;
}

.c-main-nav__close {
  @include btn-reset;
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;

  z-index: 9;

  span {
    display: inline-block;
    position: absolute;
    width: 3px;
    height: 100%;
    transform: rotate(45deg);
    background: $color-red;
    left: 0;
    right: 0;
    margin: 0 auto;

    &:first-child {
      transform: rotate(-45deg);
    }
  }
}

.c-main-nav__item {
  font-weight: 400;
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.8;

  @include bp(medium) {
    font-size: 1.2rem;
    line-height: 2.2;
  }

  &:hover .c-main-nav__dropdown {
    display: flex;
  }
}

.c-main-nav__link {
  display: inline;
  text-decoration: none;
  color: $color-white;
  font-weight: 700;

  &:hover {
    color: $color-red;
  }
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: $color-white;
  border-bottom: 1px solid $color-border;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}
