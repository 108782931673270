/*
  Project: Custom34
  Author: Daniel
 */
@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap&subset=latin-ext';

@import "settings/_global.scss";
@import "tools/_breakpoints.scss";
@import "tools/_button-reset.scss";
@import "tools/_clearfix.scss";
@import "tools/_hidden.scss";
@import "generic/_box-sizing.scss";
@import "generic/_font-face.scss";
@import "generic/_normalize.scss";
@import "generic/_reset.scss";
@import "generic/_shared.scss";
@import "elements/_blockquote.scss";
@import "elements/_headings.scss";
@import "elements/_hr.scss";
@import "elements/_html.scss";
@import "elements/_images.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_tables.scss";
@import "objects/_animate.scss";
@import "objects/_bg.scss";
@import "objects/_hamburger.scss";
@import "objects/_heading.scss";
@import "objects/_icon.scss";
@import "objects/_layout.scss";
@import "objects/_list-bare.scss";
@import "objects/_list-inline.scss";
@import "objects/_media.scss";
@import "objects/_picture.scss";
@import "objects/_section.scss";
@import "objects/_table.scss";
@import "objects/_wrapper.scss";
@import "components/_banner.scss";
@import "components/_btn.scss";
@import "components/_comment-form.scss";
@import "components/_comment.scss";
@import "components/_contact.scss";
@import "components/_cookies.scss";
@import "components/_divider.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_header.scss";
@import "components/_inventory.scss";
@import "components/_link-with-icon.scss";
@import "components/_load-more.scss";
@import "components/_main-nav.scss";
@import "components/_map.scss";
@import "components/_post.scss";
@import "components/_tiles.scss";
@import "utilities/_align.scss";
@import "utilities/_clearfix.scss";
@import "utilities/_hide.scss";
@import "utilities/_spacing.scss";
@import "utilities/_text.scss";
@import "vendor/_lb.scss";
@import "vendor/_map.scss";

.g-recaptcha {
  padding-top: 20px;
  margin-bottom: 20px;
}