.c-banner {
  position: relative;
}

.c-banner__item {
  position: relative;
  height: 0;
  padding-bottom: 39%;
  background-size: cover;
  background-repeat: no-repeat;

  video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 100vw;
  }
}

.c-banner__heading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: $color-white;

  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
}

.c-banner__indicators {
  position: absolute;
  bottom: 1rem;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
}

.c-banner__indicator {
  @include btn-reset();
  width: 0.8rem;
  height: 0.8rem;
  background: $color-very-light-grey;
  border-radius: 50%;
  margin: 0.2rem;

  cursor: pointer;

  &.is-active {
    background: $color-red;
  }
}
