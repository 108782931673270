.c-contact__info-block {
  margin: 70px auto 0;

  max-width: 45rem;

  @include bp(medium) {
    display: flex;
    justify-content: space-between;
  }
}

.c-contact__info-block__item {
  display: flex;

  align-items: center;

  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }

  text-align: left;

  @include bp(medium) {
    margin-top: 0;
    width: 49%;
  }
}

.c-contact__info-block__icon {
  width: 15px;
  display: block;
}

.c-contact__info-block__info {
  position: relative;

  width: auto;
  padding-left: 50px;

  &:before {
    content: "";
    position: absolute;
    display: block;
    left: 32px;
    height: 100%;
    width: 1px;
    background: $color-white;
  }
}

.c-contact__info-block__title {
  color: $color-white;

  font-size: 1rem;
  margin: 0;
  text-transform: uppercase;
}

.c-contact__info-block__link {
  color: $color-white;
  display: block;
  font-size: 1rem;
  text-decoration: none;
  position: relative;

  .o-icon {
    position: absolute;
    left: -38px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.c-contact__rodo {
  text-transform: uppercase;
  font-size: 0.9rem;
  color: $color-white;
  margin: 80px auto 0;

  a {
    color: $color-white;
    font-weight: 700;
  }
}
