.c-tiles {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;

  overflow: hidden;

  &--featured {
    justify-content: center;
  }

  &--artist,
  &--gallery {
    flex-wrap: wrap;
  }
}

.c-tiles__item-link {
  position: relative;
  margin: 1rem;
  width: 59%;

  &--artist,
  &--gallery {
    width: calc(50% - 2rem);

    @include bp(medium) {
      width: calc(25% - 2rem);
    }
  }
}

.c-tiles__item-title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  left: 0;
  right: 0;
  margin: 0 auto;

  font-size: 1.8rem;
  text-align: center;
  color: $color-white;
  text-transform: uppercase;
  font-weight: 700;

  z-index: 2;
}

.c-tiles__box {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 4rem;
  background: rgba($color-black, 0.7);
  color: $color-white;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.75rem;

  transition: background 0.3s ease-in;

  .c-tiles__item-link:hover & {
    background: rgba($color-black, 0.9);
  }
}