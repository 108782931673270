.o-animate {
  &--opacity {
    opacity: 0;
    transition: opacity 1s ease-in;
  }

  &--fadeIn {
    opacity: 1;
  }

  &--fadeInWithSlide {
    opacity: 1;
    animation: slideBottom 1s;
  }
}

@keyframes slideBottom {
  from { transform: translateY(50%); }
  to   { transform: translateY(0); }
}
