$o-icon__icons: (
  fb: 1,
  yt: 1,
  ig: 1,
  logo: 1,
  small-arrow: 1,
);

.o-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;

  &--inline {
    display: inline-flex;
  }

  @each $icon, $width-ratio in $o-icon__icons {
    &--icon-#{$icon} {
      width: $width-ratio * 1em;
    }
  }
}

.o-icon__symbol {
  display: block;
  width: 100%;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
