.c-load-more {
  position: relative;
  display: flex;

  justify-content: center;

  margin-top: 1.8rem;
}

.c-load-more__link {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.9rem;
  color: $color-black;
  text-align: center;

  &:hover {
    color: $color-black;
  }
}

.c-load-more__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  color: $color-white;
  background-color: $color-red;
  border-radius: 50%;
  font-size: 1.1rem;

  margin: 0 auto;

  margin-bottom: 0.5rem;

  transition: transform 0.1s ease-in;

  .c-load-more__link:hover & {
    transform: scale(1.3);
  }
}
