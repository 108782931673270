.c-inventory__item {
  margin-bottom: 2rem;

  @include bp(medium) {
    width: 33%;
    padding: 0 4rem;
  }
}

.c-inventory__item-title {
  font-weight: 700;
  font-size: 1.5rem;
}