.c-link-with-icon {
  display: flex;
  justify-content: center;

  margin-top: 3rem;
}

.c-link-with-icon__icon {
  text-align: center;
}

.c-link-with-icon__link {
  text-decoration: none;
}

.c-link-with-icon__icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  transition: transform 0.1s ease-in-out;

  .c-link-with-icon__link:hover & {
    transform: scale(1.1);
  }
}

.c-link-with-icon__title {
  position: relative;
  display: inline-block;
  margin-top: 0.75rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: $color-white;
}

.c-link-with-icon__img {
  height: 2.4rem;
}