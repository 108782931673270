.o-hamburger {
  @include btn-reset();
  width: 2rem;
  cursor: pointer;

  > span {
    position: relative;
    display: block;
    width: 100%;
    background: $color-white;
    height: 2px;

    &:first-child {
      top: 0;
      margin-bottom: 0.4rem;
      transition: top 0.2s ease-in-out 0s;
    }

    &:last-child {
      bottom: 0;
      margin-top: 0.4rem;
      transition: bottom 0.1s ease-in-out 0s;
    }
  }

  &:hover,
  &:focus {
    > span {
      position: relative;
      display: block;
      width: 100%;
      background: $color-white;
      height: 2px;

      &:first-child {
        top: -2px;
      }

      &:last-child {
        bottom: -2px;
      }
    }
  }
}
