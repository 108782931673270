.c-map__icon {
  background: $color-red;
  border-radius: 50%;
  position: relative;

  &::after {
    content: 'CUSTOM34';
    position: absolute;
    left: 35px;
    top: 7px;
  }
}
