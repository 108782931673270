.u-px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.u-py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.u-py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.u-py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.u-py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.u-py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.u-py-8 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.u-pb-8 {
  padding-bottom: 4rem;
}
