/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  padding: 1rem;
  font-size: 0.85rem;
  font-weight: 700;
  color: $color-white;
}

.c-footer__wrapper {
  @include bp(medium) {
    display: flex;
    align-items: flex-end;
  }
}

.c-footer__logo-link {
  font-size: 3.9rem;
}

.c-footer__address {
  margin-top: 1.5rem;
  text-transform: uppercase;

  @include bp(medium) {
    margin-top: 0;
    margin-left: 2.5rem;
  }
}

.c-footer__copy {
  margin-top: 1.5rem;
  @include bp(medium) {
    margin-top: 0;
  }

  display: flex;
  flex-direction: column;

  text-transform: uppercase;

  margin-left: auto;
}

.c-footer__copy-line {
  margin: 0;
  padding: 0;
}

.c-footer__designer-logo {
  display: inline-block;
  width: 3.8rem;
  margin-bottom: 1px;
}
