.c-divider {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &--small {
    padding-bottom: 25%;
  }

  &--big {
    padding-bottom: 40%;
  }
}
