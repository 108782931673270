.c-header {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;

  background-color: $color-black;


  z-index: 9;
}

.c-header__inner {
  display: flex;
  justify-content: space-between;

  padding: 0 2rem;
}

.c-header__left,
.c-header__right {
  display: flex;
  flex-direction: column;
}

.c-header__nav-item {
  transition: transform 0.1s ease-in;

  &:hover {
    transform: translateY(-0.1rem);
  }
}

.c-header__logo-link {
  display: flex;
  margin: 1rem 0;
}

.c-header__left-wrapper,
.c-header__nav {
  margin-top: auto;
  margin-bottom: 1.3rem;
}

.c-header__nav-list {
  display: flex;
  list-style-type: none;
  margin: 0;
}

.c-header__nav-item {
  height: 1rem;
  margin: 0;
  margin-left: 0.5rem;
}

.c-header__logo {
  font-size: 3.9rem;
}

.c-header__left-wrapper {
  display: flex;
  align-items: center;
}

.c-header__lang-switch {
  display: flex;
  align-items: center;

  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 700;
  color: $color-red;
  text-transform: uppercase;
  margin-left: 0.75rem;

  &:hover {
    color: rgba($color-red, 0.8);

    .o-icon {
      left: 0.3rem;
      opacity: 0.8;
    }
  }

  .o-icon {
    position: relative;
    left: 0.2rem;
    font-size: 0.5rem;
    transition: left 0.1s ease-in-out 0s;
  }
}
