.o-picture {
  display: block;
}

.o-picture__inner {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  @each $key, $ratio in $pictures {
    .o-picture--#{$key} & {
      $mobile: map-get($pictures-mobile, $key);

      // stylelint-disable max-nesting-depth, block-closing-brace-newline-after
      @if ($mobile) {
        padding-top: $mobile * 100%;

        @include bp(medium) {
          padding-top: $ratio * 100%;
        }
      } @else {
        padding-top: $ratio * 100%;
      }
      // stylelint-enable max-nesting-depth, block-closing-brace-newline-after
    }
  }
}

.o-picture__image {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: none;
  height: 100.2%;
  transform: translate(-50%, -50%);
  transition: all 500ms cubic-bezier(0.24, 0.45, 0.39, 0.95);
  backface-visibility: hidden;

  @supports (object-fit: cover) {
    width: 100.2%;
    object-fit: cover;
    object-position: center center;
  }

  .o-picture--contain & {
    height: auto;
    max-width: 100%;
    max-height: 100%;

    @supports (object-fit: contain) {
      height: 100%;
      object-fit: contain;
    }
  }

  .o-picture:hover &,
  .c-tiles__item-link:hover &,
  .c-tiles__box & {
    transform: translate(-50%, -50%) scale(1.1);
  }

  .o-picture--still:hover & {
    transform: translate(-50%, -50%);
  }
}

@each $key, $ratio in $pictures {
  @if ($ratio > 1) {
    .o-picture--#{$key} .o-picture__image {
      height: auto;
      width: 100.2%;

      @supports (object-fit: cover) {
        height: 100.2%;
      }
    }
  }
}

.o-picture__caption {
  display: none;
}
